<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="d-inline-flex justify-center align-center">
    <div class="mt-1 mr-2">
      {{ tableItem.name }}
    </div>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-img
          v-show="!tableItem.released"
          id="iiotWorkloadDetailReleasedIconLockOpen"
          :src="`/img/unlock.svg`"
          v-bind="attrs"
          max-height="40px"
          max-width="40px"
          v-on="on"
        />
        <v-img
          v-show="tableItem.released"
          id="iiotWorkloadDetailReleasedIconLockOpen"
          :src="`/img/lock.svg`"
          v-bind="attrs"
          max-height="40px"
          max-width="40px"
          v-on="on"
        />
      </template>
      <span>{{ tableItem.released ? $t('workloadDetail.released') : $t('workloadDetail.notReleased') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
